import { TbArrowRight } from "solid-icons/tb";
import { Match, type ParentComponent, Show, Switch } from "solid-js";
import styles from "./MarketingCTA.module.css";
import { StIcon, type StIconComponent } from "../icons";
import { Dynamic } from "solid-js/web";
import { classNames } from "~/lib/classNames";
import { A } from "@solidjs/router";

export const MarketingCTA: ParentComponent<{
  href?: string;
  type?: "submit" | "button";
  onClick?: () => void;
  compact?: boolean;
  /**  False if no icon, undefined will fallback to TbArrowRight, IconProps will use the icon passed */
  icon?: false | undefined | StIconComponent;
  centered?: boolean;
  iconPosition?: "left" | "right";
  class?: string;
  square?: boolean;
  dark?: boolean;
  disabled?: boolean;
}> = (props) => {
  return (
    <Dynamic
      component={props.href ? A : "button"}
      class={classNames(styles["ctas-main"], props.class)}
      href={props.href}
      onClick={props.onClick}
      type={props.type}
      disabled={props.disabled}
      aria-disabled={props.disabled || false}
    >
      <div
        class={classNames(
          styles["ctas-main-background"],
          props.compact && styles["ctas-main-background--compact"],
          props.centered && styles["ctas-main-background--centered"],
          props.iconPosition === "left" && styles["ctas-main-background--icon-left"],
          props.square && styles["ctas-main-background--square"],
          props.dark && styles["ctas-main-background--dark"],
        )}
      >
        <span>{props.children}</span>
        <Switch>
          <Match when={props.icon === undefined}>
            <StIcon icon={TbArrowRight} size={props.compact ? 18 : 24} />
          </Match>
          <Match when={props.icon}>{(icon) => <StIcon icon={icon()} size={props.compact ? 18 : 24} />}</Match>
        </Switch>
      </div>
    </Dynamic>
  );
};
