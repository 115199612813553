import { classNames } from "~/lib/classNames";
import styles from "./MarketingGradientBlob.module.css";

export const MarketingGradientBlob = (props: {
  green?: boolean;
  bottom?: boolean;
  right?: boolean;
  left?: boolean;
}) => {
  return (
    <div
      class={classNames(
        styles["gradient-blob"],
        props.bottom && styles["gradient-blob--bottom"],
        props.right && styles["gradient-blob--right"],
        props.left && styles["gradient-blob--left"],
      )}
    >
      <img
        src={props.green ? "/images/home/bg-green-gradient.avif" : "/images/home/bg-purple-gradient.avif"}
        aria-hidden
      />
    </div>
  );
};
